import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
import AuthClassicLayout from 'src/layouts/auth/classic';
import JwtLoginCustomer from 'src/pages/auth/jwt/customerLogin';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to='/auth/jwt/login' replace />,
    },

    // Auth routes
    ...authRoutes,

    {
      path: '/customer/login',
      element: (
        <AuthClassicLayout title='Hi, Welcome to ATROS Customer Panel'>
          <JwtLoginCustomer />
        </AuthClassicLayout>
      ),
    },

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
