import { Helmet } from 'react-helmet-async';
// sections
import JwtLoginCustomerView from 'src/sections/auth/jwt/jwt-login-customer';

// ----------------------------------------------------------------------

export default function JwtLoginCustomer() {
    return (
        <>
            <Helmet>
                <title> Jwt: Customer Login</title>
            </Helmet>

            <JwtLoginCustomerView />
        </>
    );
}
