import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider, Button, Checkbox } from '@mui/material';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell'; 
import TableRow from '@mui/material/TableRow';
import { useLocation } from 'react-router-dom'; 

const AdvancePaymentInvoice = () => {
  const location = useLocation(); 
  const { invoiceData, rowOrders } = location.state || {}; 

  useEffect(() => {
    console.log('Received invoice data:', invoiceData);
    console.log('Received rowOrders data:', rowOrders);
  }, [invoiceData, rowOrders]);

  const handleDownload = async () => {
    console.log('Exporting Advance Payment Invoice...');

    const invoiceContainer = document.getElementById('invoice-container'); 
    const button = document.getElementById('download-button');

    if (button) {
      button.style.display = 'none';
    }

    const canvas = await html2canvas(invoiceContainer, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');

    const doc = new JsPDF('p', 'mm', 'a4'); 

    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();

    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const aspectRatio = imgWidth / imgHeight;

    let pdfImgWidth = pdfWidth - 40;
    let pdfImgHeight = pdfImgWidth / aspectRatio;

    if (pdfImgHeight > pdfHeight - 40) {
      pdfImgHeight = pdfHeight - 40;
      pdfImgWidth = pdfImgHeight * aspectRatio;
    }

    const margin = 20; 
    doc.addImage(imgData, 'PNG', margin, margin, pdfImgWidth, pdfImgHeight);

    const dateString = new Date().toISOString().slice(0, 10).replace(/-/g, ''); 
    const filename = `advance_payment_invoice_${dateString}.pdf`;
    doc.save(filename);

    if (button) {
      button.style.display = 'inline-block';
    }
  };

  return (
    <div id="invoice-container" style={{ width: '800px', margin: '20px auto', fontFamily: 'Arial, sans-serif' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} align="right">
          <Button
            id="download-button"
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
          >
            Download PDF
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" sx={{ marginBottom: '30px' }}>
            ATROS Water
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            ATROS WATER, J416/1, MIDC Rd, MIDC
            Bhosari, Pune, Maharashtra - 411026
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Typography variant="body1">
            Payment Receipt
            <br />
            Receipt Number: {invoiceData?.invoiceNumber || 'N/A'}
            <br />
            Date: {invoiceData ? dayjs(invoiceData.date).format('DD/MM/YYYY') : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            Received From: {rowOrders?.customerId.userId.name || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Typography variant="body1">
            Amount: ₹{rowOrders?.advanceAmount || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            Machine Model/Purpose: {rowOrders?.product.name || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Total Amount</TableCell>
                <TableCell align="right">₹{rowOrders?.advanceAmount || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Amount Received</TableCell>
                <TableCell align="right">₹{rowOrders?.advanceAmount || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Due Balance</TableCell>
                <TableCell align="right">₹0</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            Received By: ATROS Water
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Typography variant="body1">
            Sign: ________________
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Checkbox value="cash" />
              <Typography variant="body1">Cash</Typography>
            </Grid>
            <Grid item xs={4}>
              <Checkbox value="creditCard" />
              <Typography variant="body1">Credit Card</Typography>
            </Grid>
            <Grid item xs={4}>
              <Checkbox value="upi" />
              <Typography variant="body1">UPI</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdvancePaymentInvoice;
