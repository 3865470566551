import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { user } = useAuthContext();
  // const roleType = localStorage.getItem('roleType');

  const data = useMemo(() => {
    let itemsToRender;
    const menuItemsForAdmin = [
      {
        subheader: user?.roleType ?? 'ADMIN',
        items: [
          { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: 'Devices', path: paths.dashboard.devices, icon: ICONS.dashboard },

          {
            title: 'User Management',
            path: paths.dashboard.three,
            icon: ICONS.user,
            children: [
              { title: 'Customer', path: paths.dashboard.user.customer },
              { title: 'Distributor', path: paths.dashboard.user.distributor },
              { title: 'Technician', path: paths.dashboard.user.technician },
            ],
          },
          {
            title: 'Product Management',
            path: paths.dashboard.product.root,
            icon: ICONS.user,
            children: [
              { title: 'Category', path: paths.dashboard.product.category },
              { title: 'Subcategory', path: paths.dashboard.product.subcategory },
              { title: 'Products', path: paths.dashboard.product.products },
            ],
          },
          {
            title: 'Subscription Management',
            path: paths.dashboard.subscription,
            icon: <SubscriptionsIcon />,
          },
          {
            title: 'Sales Management',
            path: paths.dashboard.sales,
            icon: ICONS.booking,
          },
          { title: 'Order Management', path: paths.dashboard.order, icon: ICONS.order },
          // { title: 'Tech Support', path: paths.dashboard.three, icon: ICONS.tour },
          // { title: 'Accounting Reports', path: paths.dashboard.three, icon: ICONS.banking },
          {
            title: 'Report Management',
            path: paths.dashboard.reports.root,
            icon: ICONS.user,
            children: [
              { title: 'Customer Reports', path: paths.dashboard.reports.customer },
              { title: 'Payment Reports', path: paths.dashboard.reports.payment },
              { title: 'Rent Reports', path: paths.dashboard.reports.emiReports }
            ],
          },
        ],
      },
    ];

    const menuItemsForDistributors = [
      {
        subheader: user?.roleType ?? 'ADMIN',
        items: [
          { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
          {
            title: 'User Management',
            path: paths.dashboard.three,
            icon: ICONS.user,
            children: [{ title: 'Customer', path: paths.dashboard.user.customer }],
          },
          { title: 'Order Management', path: paths.dashboard.order, icon: ICONS.order },
        ],
      },
    ];

    const menuItemsForTechnician = [
      {
        subheader: user?.roleType ?? 'ADMIN',
        items: [
          {
            title: 'Technician Tasks List',
            path: paths.dashboard.user.technicianTaskList,
            icon: ICONS.analytics,
          },
          // { title: 'Tasks', path: paths.dashboard.three, icon: ICONS.analytics }
        ],
      },
    ];

    const menuItemsForCustomer = [
      {
        subheader: 'CUSTOMER',
        items: [
          {
            title: 'Customer Details',
            path: paths.dashboard.customerDetails,
            icon: ICONS.cus,
          },
        ],
      },
    ];
    
    if (user?.roleType) {
      if (user?.roleType === 'DISTRIBUTOR') {
        itemsToRender = menuItemsForDistributors;
      } else if (user?.roleType === 'TECHNICIAN') {
        itemsToRender = menuItemsForTechnician;
      } else if (user?.roleType === 'CUSTOMER') {
        itemsToRender = menuItemsForCustomer;
      }else{
        itemsToRender = menuItemsForAdmin;
      
      }
    } else {
      itemsToRender = menuItemsForAdmin;
    };

    console.log(itemsToRender)

    return itemsToRender;
  }, [user?.roleType]);

  return data;
}
