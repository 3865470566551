import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import AdvancePaymentInvoice from 'src/sections/order/advancePaymentInvoice';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/one'));
const PageThree = lazy(() => import('src/pages/dashboard/three'));
const PaymentReport = lazy(() => import('src/pages/reports/paymentReport'));
const EmiReport = lazy(() => import('src/pages/reports/emiReports'));

// Devices
const Devices = lazy(() => import('src/pages/devices/device'));

// Reports
const CustomerReport = lazy(() => import('src/pages/reports/customerReport'));

// users
const UserCustomer = lazy(() => import('src/pages/users/customer'));
const UserDistributor = lazy(() => import('src/pages/users/distributor'));
const UserTechnician = lazy(() => import('src/pages/users/technician'));

const OrderManagement = lazy(() => import('src/pages/order/orders'));
const OrderInvoice = lazy(() => import('src/pages/order/orderInvoice'));
const OrderEMIInvoice = lazy(() => import('src/pages/order/emiInvoice'));
const OrderAdvAmountInvoice = lazy(() => import('src/sections/order/advanceAmountInvoice'));
const ForceCloseEmis = lazy(() => import('src/sections/order/forceCloseEmis'));

const SalesPage = lazy(() => import('src/pages/sales'));

// Products
const ProductCategory = lazy(() => import('src/pages/products/category'));
const SubCategory = lazy(() => import('src/pages/products/subcategory'));
const Products = lazy(() => import('src/pages/products/products'));

// Subscription Page
const SubscriptionPage = lazy(() => import('src/pages/subscription'));

const TechTasksList = lazy(() => import('src/pages/technician/taskList'));

const ReportEmiInvoice = lazy(() => import('src/sections/reports/emiInvoice'));

// CUSTOMER DETAILS
const CustomerDetails = lazy(() => import('src/pages/customerDetails/view'));
// ------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'customerDetails', element: <CustomerDetails /> },
      { path: 'devices', element: <Devices /> },
      {
        path: 'report', // considers key from paths.js file not value
        children: [
          { path: 'customer', element: <CustomerReport /> },
          { path: 'emi-invoice', element: <ReportEmiInvoice /> }, // VIEW REPORT INVOICE IN CUSTOMER REPORT
          { path: 'payment', element: <PaymentReport /> },
          { path: 'emiReports', element: <EmiReport /> },
        ],
      },
      {
        path: 'user', // considers key from paths.js file not value
        children: [
          { path: 'customer', element: <UserCustomer /> },
          { path: 'distributor', element: <UserDistributor /> },
          { path: 'technician', element: <UserTechnician /> },
          { path: 'technicianTaskList', element: <TechTasksList /> },
        ],
      },
      {
        path: 'product', // considers key from paths.js file not value
        children: [
          { path: 'category', element: <ProductCategory /> },
          { path: 'subcategory', element: <SubCategory /> },
          { path: 'products', element: <Products /> },
        ],
      },
      { path: 'subscription', element: <SubscriptionPage /> },
      { path: 'three', element: <PageThree /> },
      { path: 'order', element: <OrderManagement /> },
      { path: 'order/invoice', element: <OrderInvoice /> },
      { path: 'order/emiInvoice', element: <OrderEMIInvoice /> },
      { path: 'order/advanceAmountInvoice', element: <OrderAdvAmountInvoice /> },
      { path: 'order/forceCloseEmis', element: <ForceCloseEmis /> },
      { path: 'sales', element: <SalesPage /> },
      { path: 'order/advancePaymentInvoice', element: <AdvancePaymentInvoice /> },
    ],
  },
];
