// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    customerDetails: `${ROOTS.DASHBOARD}/customerDetails`,
    one: `${ROOTS.DASHBOARD}/one`,
    devices: `${ROOTS.DASHBOARD}/devices`,
    three: `${ROOTS.DASHBOARD}/three`,
    order: `${ROOTS.DASHBOARD}/order`,
    subscription: `${ROOTS.DASHBOARD}/subscription`,
    sales: `${ROOTS.DASHBOARD}/sales`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
    reports: {
      root: `${ROOTS.DASHBOARD}/report`,
      customer: `${ROOTS.DASHBOARD}/report/customer`,
      payment: `${ROOTS.DASHBOARD}/report/payment`,
      emiReports: `${ROOTS.DASHBOARD}/report/emiReports`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      category: `${ROOTS.DASHBOARD}/product/category`,
      subcategory: `${ROOTS.DASHBOARD}/product/subcategory`,
      products: `${ROOTS.DASHBOARD}/product/products`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      customer: `${ROOTS.DASHBOARD}/user/customer`,
      distributor: `${ROOTS.DASHBOARD}/user/distributor`,
      technician: `${ROOTS.DASHBOARD}/user/technician`,
      users: `${ROOTS.DASHBOARD}/users`,
      technicianTaskList: `${ROOTS.DASHBOARD}/user/technicianTaskList`,
    },
  },
};
